var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "phone-call-test" },
    [
      _c(
        "div",
        { staticClass: "phone-call", on: { click: _vm.onClicked } },
        [
          _vm._t("default", null, {
            onCallStatuText: _vm.onCallStatuText,
            isWaiting: _vm.isWaiting,
          }),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "phone-call-dialog",
          attrs: {
            title: _vm.titleText,
            visible: _vm.dialogVisible,
            width: "480px",
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm._onCancel,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.beforeCall,
                  expression: "beforeCall",
                },
              ],
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "phone-call-box",
                },
                [
                  !_vm.ifMustAddLogs && _vm.editStatus == 0
                    ? _c("p", { staticClass: "phone-call-tip" }, [
                        _vm._v(
                          "确定拨号后，猎必得会先拨通您的电话，请注意接听"
                        ),
                      ])
                    : _vm._e(),
                  !_vm.ifMustAddLogs && _vm.editStatus == 1
                    ? _c("p", { staticClass: "phone-call-tip" }, [
                        _vm._v("设置固定电话用于拨打候选人电话"),
                      ])
                    : _vm._e(),
                  !_vm.ifMustAddLogs && _vm.editStatus == 3
                    ? _c("p", { staticClass: "phone-call-tip" }, [
                        _vm._v("修改您的拨号固话"),
                      ])
                    : _vm._e(),
                  _vm.phoneList.length >= 0 && _vm.editStatus == 0
                    ? _c(
                        "div",
                        { staticClass: "phone-call-list" },
                        _vm._l(_vm.phoneList, function (item, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: item.number },
                                  model: {
                                    value: _vm.choosedPhone,
                                    callback: function ($$v) {
                                      _vm.choosedPhone = $$v
                                    },
                                    expression: "choosedPhone",
                                  },
                                },
                                [_vm._v(_vm._s(item.number))]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "edit-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEditPhone(item)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              item.numberType == 1
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "delete-btn",
                                      on: { click: _vm.handleDeleteTelephone },
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm.ifMustAddLogs
                    ? _c("p", { staticClass: "phone-call-tip" }, [
                        _vm._v("今天有"),
                        _c("span", {
                          domProps: { textContent: _vm._s(_vm.needLogsNum) },
                        }),
                        _vm._v(
                          "次通话没有添加寻访记录，请补录后继续使用免费通话功能。"
                        ),
                      ])
                    : _vm._e(),
                  _vm.enableShowLogTip
                    ? _c("p", { staticClass: "phone-call-tip" }, [
                        _vm._v("\n                    提示：您漏添加了"),
                        _c("span", {
                          staticClass: "text-primary",
                          domProps: { textContent: _vm._s(_vm.needLogsNum) },
                        }),
                        _vm._v("条寻访记录哦！\n                    "),
                        _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href: _vm.candidateHomeUrl,
                            },
                          },
                          [_vm._v("去补录")]
                        ),
                      ])
                    : _vm._e(),
                  _vm.editStatus != 0
                    ? _c(
                        "el-form",
                        {
                          ref: "editForm",
                          attrs: {
                            model: _vm.editForm,
                            rules: _vm.editFormRules,
                          },
                        },
                        [
                          _vm.editStatus == 1
                            ? _c(
                                "div",
                                { staticClass: "phone-call-input-box" },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "telephoneInput" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "例如：0387-6543256",
                                        },
                                        model: {
                                          value: _vm.editForm.telephoneInput,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editForm,
                                              "telephoneInput",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "editForm.telephoneInput",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.editStatus == 2
                            ? _c(
                                "div",
                                { staticClass: "phone-call-input-box" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mobile-item",
                                      attrs: { prop: "mobileInput" },
                                    },
                                    [
                                      _c(
                                        "el-row",
                                        {
                                          staticClass:
                                            "mobile-input-row mobile-row",
                                        },
                                        [
                                          _c("span", [_vm._v("手机号")]),
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "输入手机号",
                                              maxlength: "11",
                                            },
                                            on: {
                                              input: _vm.handleMobileChange,
                                            },
                                            model: {
                                              value: _vm.editForm.mobileInput,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editForm,
                                                  "mobileInput",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "editForm.mobileInput",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "msg-code-item",
                                      attrs: { prop: "msgCode" },
                                    },
                                    [
                                      _c(
                                        "el-row",
                                        {
                                          staticClass:
                                            "mobile-input-row code-row",
                                        },
                                        [
                                          _c("span", [_vm._v("验证码")]),
                                          _c(
                                            "el-input",
                                            {
                                              attrs: {
                                                placeholder: "输入短信验证码",
                                                maxlength: "6",
                                              },
                                              model: {
                                                value: _vm.editForm.msgCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editForm,
                                                    "msgCode",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression: "editForm.msgCode",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  staticClass: "get-code-btn",
                                                  class: {
                                                    green:
                                                      _vm.canGetCode &&
                                                      !_vm.timer,
                                                  },
                                                  attrs: {
                                                    slot: "suffix",
                                                    disabled:
                                                      !_vm.canGetCode ||
                                                      !!_vm.timer,
                                                    type: "button",
                                                    loading: _vm.codeLoading,
                                                  },
                                                  on: { click: _vm.getMsgCode },
                                                  slot: "suffix",
                                                },
                                                [
                                                  !_vm.codeGetted
                                                    ? [_vm._v("获取验证码")]
                                                    : [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.timer
                                                              ? `${_vm.countDown}秒后`
                                                              : ""
                                                          ) + "重新获取"
                                                        ),
                                                      ],
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.editStatus == 3
                            ? _c(
                                "div",
                                { staticClass: "phone-call-input-box" },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "telephoneInput" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "例如：0387-6543256",
                                        },
                                        model: {
                                          value: _vm.editForm.telephoneInput,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editForm,
                                              "telephoneInput",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "editForm.telephoneInput",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.ifMustAddLogs
                ? _c(
                    "a",
                    {
                      staticClass: "phone-call-btn",
                      attrs: { href: _vm.candidateHomeUrl, target: "_blank" },
                    },
                    [_vm._v("补录寻访记录")]
                  )
                : _vm._e(),
            ]
          ),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.beforeCall,
                expression: "!beforeCall",
              },
            ],
            staticClass: "phone-call-result",
            domProps: { innerHTML: _vm._s(_vm.callResults) },
          }),
          !_vm.ifMustAddLogs && _vm.editStatus == 0
            ? _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.beforeCall,
                      expression: "beforeCall",
                    },
                  ],
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _vm.phoneList.length == 1
                    ? _c("el-button", { on: { click: _vm.setTelephone } }, [
                        _vm._v("设置固话"),
                      ])
                    : _vm._e(),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.doCall } },
                    [_vm._v("拨打")]
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.ifMustAddLogs && _vm.editStatus != 0
            ? _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.beforeCall,
                      expression: "beforeCall",
                    },
                  ],
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.handleBack } }, [
                    _vm._v("返回"),
                  ]),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.saveBtnLoading,
                          expression: "saveBtnLoading",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSave },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-dialog",
            {
              attrs: {
                width: "300px",
                title: "提示",
                visible: _vm.deleteDialogVisible,
                "show-close": false,
                "append-to-body": true,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.deleteDialogVisible = $event
                },
              },
            },
            [
              _c("p", [_vm._v("确定要删除固话吗？")]),
              _c(
                "span",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.deleteDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.deleteBtnLoading,
                          expression: "deleteBtnLoading",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.deleteTelephone },
                    },
                    [_vm._v("确认")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }