<template>
    <div class="phone-call-test">
        <div class="phone-call" v-on:click="onClicked">
            <slot :onCallStatuText='onCallStatuText' :isWaiting='isWaiting'></slot>
        </div>
        <el-dialog
            :title="titleText"
            class="phone-call-dialog"
            :visible="dialogVisible"
            width="480px"
            v-loading="loading"
            :append-to-body="true"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="_onCancel"
        >
            <div v-show="beforeCall">
                <div class="phone-call-box" v-loading="loading">
                    <p class="phone-call-tip" v-if="!ifMustAddLogs && editStatus == 0">确定拨号后，猎必得会先拨通您的电话，请注意接听</p>
                    <p class="phone-call-tip" v-if="!ifMustAddLogs && editStatus == 1">设置固定电话用于拨打候选人电话</p>
                    <!-- <p class="phone-call-tip" v-if="!ifMustAddLogs && editStatus == 2">修改您的拨号电话</p> -->
                    <p class="phone-call-tip" v-if="!ifMustAddLogs && editStatus == 3">修改您的拨号固话</p>

                    <div class="phone-call-list"  v-if="phoneList.length >= 0 && editStatus == 0">
                        
                            <div v-for="(item, index) in phoneList" :key="index">
                                <el-radio v-model="choosedPhone" :label="item.number">{{item.number}}</el-radio>
                                <span class="edit-btn" @click="handleEditPhone(item)">编辑</span>
                                <span v-if="item.numberType == 1" class="delete-btn" @click="handleDeleteTelephone">删除</span>
                            </div>
                    </div>
                    <!-- <div class="phone-call-list phone-call-from-list" v-if="isCFUser">
                        <span>呼出号码选项：</span>
                        <el-radio key="2" v-model="callArea" label="">随机</el-radio>
                        <el-radio key="1" v-model="callArea" label="西宁">西宁固话</el-radio>
                        <p>
                            提示：西宁固话可以接通
                            <i>北京号码</i>
                            ，请根据实际情况进行选择呼出号码
                        </p>
                    </div> -->
                    <p class="phone-call-tip" v-if="ifMustAddLogs">今天有<span v-text="needLogsNum"></span>次通话没有添加寻访记录，请补录后继续使用免费通话功能。</p>
                    <p class="phone-call-tip" v-if="enableShowLogTip">
                        提示：您漏添加了<span class="text-primary" v-text="needLogsNum"></span>条寻访记录哦！
                        <a target="_blank" v-bind:href="candidateHomeUrl">去补录</a>
                    </p>

                    <el-form v-if="editStatus != 0" ref="editForm" :model="editForm" :rules="editFormRules">
                        <div class="phone-call-input-box" v-if="editStatus == 1">
                            <el-form-item prop="telephoneInput">
                                <el-input placeholder="例如：0387-6543256" v-model.trim="editForm.telephoneInput"></el-input>
                            </el-form-item>
                        </div>
                        <div class="phone-call-input-box" v-if="editStatus == 2">
                            <el-form-item class="mobile-item" prop="mobileInput">
                                <el-row class="mobile-input-row mobile-row">
                                    <span>手机号</span>
                                    <el-input 
                                        placeholder="输入手机号" 
                                        maxlength="11" 
                                        v-model.trim="editForm.mobileInput" 
                                        @input="handleMobileChange"
                                    ></el-input>
                                </el-row>
                            </el-form-item>
                            <el-form-item class="msg-code-item" prop="msgCode">
                                <el-row class="mobile-input-row code-row">
                                    <span>验证码</span>
                                    <el-input placeholder="输入短信验证码" maxlength="6" v-model.trim="editForm.msgCode">
                                        <el-button
                                            slot="suffix"
                                            class="get-code-btn"
                                            :disabled="!canGetCode || !!timer" 
                                            @click="getMsgCode"
                                            type="button"
                                            :loading="codeLoading"
                                            :class="{'green': canGetCode && !timer}"
                                            >
                                                <template v-if="!codeGetted">获取验证码</template>
                                                <template v-else>{{timer ? `${countDown}秒后` : ''}}重新获取</template>
                                        </el-button>
                                    </el-input>
                                </el-row>
                            </el-form-item>
                        </div>
                        <div class="phone-call-input-box" v-if="editStatus == 3">
                            <el-form-item prop="telephoneInput">
                                <el-input placeholder="例如：0387-6543256" v-model.trim="editForm.telephoneInput"></el-input>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>
                <!-- <div class="phone-call-bottom" v-if="!ifMustAddLogs && editStatus == 0">
                    <button class="phone-call-btn" v-on:click="doCall">立即拨打</button>
                    <a class="phone-call-link" href="javascript:void(0)" @click="_onSetPhoneClick">设置固话号 >></a>
                </div> -->
                <a class="phone-call-btn" v-bind:href="candidateHomeUrl" target="_blank" v-if="ifMustAddLogs">补录寻访记录</a>
            </div>
            <div class="phone-call-result"  v-html="callResults" v-show="!beforeCall"></div>

            <span v-if="!ifMustAddLogs && editStatus == 0" v-show="beforeCall" slot="footer" class="dialog-footer">
                <el-button v-if="phoneList.length == 1" @click="setTelephone">设置固话</el-button>
                <el-button type="primary" @click="doCall">拨打</el-button>
            </span>

            <span v-if="!ifMustAddLogs && editStatus != 0" v-show="beforeCall" slot="footer" class="dialog-footer">
                <el-button @click="handleBack">返回</el-button>
                <el-button type="primary" @click="handleSave" v-loading="saveBtnLoading">保存</el-button>
            </span>

            <el-dialog
                width="300px"
                title="提示"
                :visible.sync="deleteDialogVisible"
                :show-close="false"
                :append-to-body="true"
            >
                <p>确定要删除固话吗？</p>
                <span slot="footer">
                    <el-button @click="deleteDialogVisible = false;">取消</el-button>
                    <el-button type="primary" @click="deleteTelephone" v-loading="deleteBtnLoading">确认</el-button>
                </span>
            </el-dialog>
        </el-dialog>
    </div>
</template>

<script>
    import { phone_call, candidate } from '#/js/config/api.json';

    const LINK_CANDIDATE = '/#/candidateDetail/%p';
    const TIP_CALLING = '正在呼叫您的手机，请接听......';
    const TIP_CALL_ERROR = '号码错误，请确认后再重拨';
    const TIP_CALL_FAILED = '呼叫失败，请稍后再试';
    const TIP_CALL_HELP = '个人简历仅支持使用电话助手拨打，<br/>请先安装电话助手';

    import phoneService from '#/js/service/phoneService.js';
    import { internationalMobile } from '#/js/util/validators.js'

    export  default {
        name: 'PhoneCall',
        components: {
        },
        props:{
            objectId: String,
            callTo: Number,
            calledMobile: String,
            tbdResumeId:{
                type: String
            },
            sourceFrom:{
                type: String
            },
            isFirmResume:{
                type:Boolean,
                default:true
            }

        },
        computed:{
            isCFUser() {
                return this.$store.state.user.userInfo.isCFUser;
            },
            webSocketUrl(){
                let url = this.$store.state.user.userInfo.webSocketUrl;
                url = url.replace('/web', '/api');
                return url;
                //return 'http://192.168.1.115:8082/web' //测试连本地
            },
            userMobile(){
                return this.$store.state.user.userInfo.mobile;
                //return 'http://192.168.1.115:8082/web' //测试连本地
            },
            resumeId(){
                return this.tbdResumeId?this.tbdResumeId:this.candidateInfo.resumeId;
            },
            titleText() {
                let str = '拨打电话';
                if (this.editStatus == 1) {
                    str = '设置固话';
                }else if (this.editStatus == 2) {
                    str = '修改手机号';
                }else if (this.editStatus == 3) {
                    str = '修改固话';
                }
                return str;
            }
        },
        data(){
            return{
                dialogVisible: false,
                loading: false,
                editStatus: 0, // 0:拨打电话, 1:设置固话, 2:修改手机, 3:修改固话
                onCallStatu : 0,
                onCallStatuText : '免费拨打',
                isWaiting : false,
                choosedPhone: '',
                needLogsNum: 0,
                phoneList: [],
                showPhoneSet: false,
                beforeCall: true,
                callResults: '',
                candidateHomeUrl: '',
                enableShowLogTip: false,
                ifMustAddLogs: false,
                candidateInfo:{
                    candidateId:'',
                    resumeId:'',
                    isFirmResume: true,
                },//用于新项目的candidate传值
                calledNumber: '',
                actualMobile: '',
                actualTelephone: '',
                saveBtnLoading: false,
                codeLoading: false,
                codeGetted: false,
                canGetCode: false,
                countDown: 60*5,
                timer: null,
                deleteDialogVisible: false,
                deleteBtnLoading: false,
                editForm: {
                    telephoneInput: '',
                    mobileInput: '',
                    msgCode: '',
                },
                editFormRules: {
                    telephoneInput: [
                        {required: true, message: "请输入固定电话", trigger: ["blur", "change"],},
                        { validator: this.validateTelephone, trigger: ["blur", "change"] },
                    ],
                    mobileInput: [
                        {required: true, message: "请输入手机号", trigger: ["blur", "change"],},
                        { validator: this.validateMobile, trigger: ["blur", "change"] },
                    ],
                    msgCode: [
                        { required: true, message: '请输入短信验证码', trigger: ['blur', 'change'] },
                        { pattern: /^[0-9]{4}$/, message: '短信验证码必须为4位数字', trigger: ['blur', 'change'] }
                    ],
                },
                // callArea: '', //选择华为云呼出地点
            }
        },
        watch:{
            onCallStatu(val) {
                let text = '免费拨打';
                switch(val) {
                    case 0 :
                        text = '免费拨打';
                        this.isWaiting=false;
                        break;
                    case 1 :
                        text = '正在拨出';
                        this.isWaiting=true;
                        break;
                    case 2:
                        text = '正在通话';
                        this.isWaiting=true;
                        break;
                    case 3:
                        if(this.callTo === 0) {
                            text = '通话结束';
                            this.clearOnCallStatu();
                        } else {
                            text = '生成录音';
                            this.isWaiting=true;
                        }
                        break;
                    case 4:
                        text = '未接通';
                        this.clearOnCallStatu();
                        break;
                    case 5:
                        text = '录音已生成';
                        this.clearOnCallStatu();
                        break;
                    default:
                        this.isWaiting=false;
                        break;
                }
                this.onCallStatuText=text;
            },
        },
        mounted(){
        },
        methods:{
            validateTelephone(rule, value, callback) {
                console.log(value);
                if(_isDangerousString(value)){
                    callback(new Error('您输入的内容包含非法字符，请重新输入'));
                } else if (!/^\d{2,5}-\d{7,8}$/.test(value)) {
                    callback(new Error('固定电话格式不正确，请重新输入'));
                } else {
                    callback();
                }
            },
            validateMobile(rule, value, callback) {
                console.log(value);
                if(_isDangerousString(value)){
                    callback(new Error('您输入的内容包含非法字符，请重新输入'));
                } else if (!internationalMobile(value)) {
                    callback(new Error('手机号格式不正确，请重新输入'));
                } else {
                    callback();
                }
            },
            clearOnCallStatu(){
                setTimeout(()=>{
                    this.onCallStatu = 0;
                },3000)
                this.isWaiting = false;
            },

            showCallDialog(config) {
                const count = config.calledCountWithoutInquiryLog;
                this.needLogsNum = count;

                if (config.canCall) {
                    if (count > 0) {
                        this.enableShowLogTip = true;
                        this.candidateHomeUrl = LINK_CANDIDATE.replace('%p', config.candidateId);
                    }
                } else {
                    this.ifMustAddLogs = true;
                }
                this.dialogVisible = true;
            },

            _onCancel() {
                this.dialogVisible = false;
                this.$emit('cancelPhoneCall');
                setTimeout(() => {
                    this.editStatus = 0;
                    this.editForm.telephoneInput = '';
                    this.editForm.mobileInput= '';
                }, 500)
            },

            _onSetPhoneClick() {
                this.editStatus = 1;
            },

            canDoCall() {
                return _request({
                    method: 'GET',
                    url: phone_call.can_do_call,
                    throwBusinessError: true
                }).then(async (res) => {
                    try {
                        let list = await phoneService.getPhoneList();
                        list = list.sort((a, b) => {
                            return a.numberType - b.numberType
                        });
                        this.phoneList = list;

                        this.phoneList.forEach(item => {
                            if (item.numberType == 0) {
                                this.actualMobile = item.number;
                                this.choosedPhone = item.number;
                            }else if (item.numberType == 1) {
                                this.actualTelephone = item.number;
                            }
                        });
                    } catch (error) {
                        this.onCallStatu=0;
                        shortTips('网络连接失败，请刷新重试');
                    }
                    return res;
                }).catch(err => {
                    this.onCallStatu = 0;
                    shortTips('网络连接失败，请刷新重试');
                })
            },

            // getPhoneList() {
            //     return _request({
            //         method: 'GET',
            //         url: phone_call.get_phone_list,
            //         throwBusinessError: true
            //     })
            // },

            showCallResult(text) {
                if(!this.dialogVisible) {
                    this.dialogVisible = true;
                }
                this.loading = false;
                this.beforeCall = false;
                this.callResults = text;
                setTimeout(() => {
                    this.beforeCall = true;
                    this.dialogVisible = false;
                }, 3000)
                $('.el-popover').hide();//某种情况未能触发tooltip的移出事件，故手动做一次隐藏
            },

            doCall() {
                let callingNumber = '';
                if(this.phoneList.length == 1){
                    callingNumber =  this.phoneList[0].number;
                }else{
                    callingNumber = this.choosedPhone;
                }
                let data = {
                    callTarget: this.callTo,
                    objectId: this.resumeId,
                    callerNumber: callingNumber || this.userMobile,
                    calledNumber: this.calledNumber || this.calledMobile || ''
                };
                this.loading = true;
                // if (this.isCFUser && this.callArea != '') {
                //     data.area = this.callArea;
                //     //CF用户需要选择华为云电话呼出地址，默认为随机
                //     phoneService.doCallWithArea(data)
                //         .then(res => {
                //             this.showCallResult(TIP_CALLING);
                //             this.$store.dispatch('setLastCallResumeId', data.objectId);
                //         }).catch(err => {
                //             this.showCallResult(TIP_CALL_ERROR);
                //         })
                // } else {
                    phoneService.doCall(data)
                        .then(res => {
                            this.showCallResult(TIP_CALLING);
                            this.$store.dispatch('setLastCallResumeId', data.objectId);
                        }).catch(err => {
                            this.showCallResult(TIP_CALL_ERROR);
                        })
                // }
            },

            callByNetPhone() {
                this.canDoCall()
                    .then((result) => {
                        this.showCallDialog(result);
                    }).catch(err => {
                        console.log(err)
                    });
            },

            onClicked() {
                if (!this.objectId && !this.candidateInfo.candidateId && !this.resumeId) {
                    console.log("🚀 ~ onClicked ~ a:", '1')
                    this.showCallResult(TIP_CALL_ERROR);
                }

                if(this.candidateInfo.isFirmResume && this.isFirmResume) {
                    console.log("🚀 ~ onClicked ~ a:", '2')
                    this.callByNetPhone();
                } else {
                    console.log("🚀 ~ onClicked ~ a:", '3')
                    this.showCallResult(TIP_CALL_HELP);
                }
            },
            callByWorkbench(json){
                //用于给新项目工作台表格调用的拨打电话，json传需要的candidateInfo所需的字段
                if(!json) return;
                this.candidateInfo = Object.assign({}, this.candidateInfo, json);
                this.onClicked();
            },
            callJson(){
                //userId等值的取值和传值
                let json = {
                    userId: this.$store.state.user.userInfo.id,
                    userName: this.$store.state.user.userInfo.nickname,
                }
                return json;
            },
            handleBack() {
                if (this.editStatus == 1) {
                    
                } else if (this.editStatus == 2) {
                    this.canGetCode = false;
                    this.codeGetted = false;
                    this.codeLoading = false;
                    this.countDown = 60*5;
                    this.timer && clearInterval(this.timer);
                    this.timer = null;
                }
                this.editStatus = 0;
            },
            handleSave() {
                if (this.editStatus == 1 || this.editStatus == 3) {
                    this.submitTelephone();
                } else {
                    this.submitMobile();
                }
                // this.editStatus = 0;
            },
            submitTelephone() {
                if (this.saveBtnLoading) {
                    return false;
                }

                if (this.editForm.telephoneInput == this.actualTelephone) {
                    this.editStatus = 0;
                    return false;
                }


                this.$refs['editForm'].validate((valid) => {
                    if (valid) {
                        this.saveBtnLoading = true;
                        phoneService.setPhone({
                            telephoneNumber: this.editForm.telephoneInput,
                            mobileNumber: '',
                            verifyCode: ''
                        }).then(res => {
                            const obj = {
                                number: this.editForm.telephoneInput,
                                numberType: 1
                            };
                            this.actualTelephone = this.editForm.telephoneInput;
                            if (this.editStatus == 1) {
                                this.phoneList.push(obj);
                                shortTips('设置固话成功！');
                            }
                            if (this.editStatus == 3) {
                                if(this.phoneList.length == 2){
                                    this.phoneList.forEach((phone, i) =>{
                                        if(phone.numberType == 1){
                                            this.phoneList.splice(i, 1, obj);
                                            return false;
                                        }
                                    })
                                }
                                shortTips('更新固话成功！');
                            }
                            this.editStatus = 0;

                            // 加华为白名单
                            phoneService.addMobileWhiteList(this.editForm.telephoneInput);
                        }).finally(() => {
                            this.saveBtnLoading = false;
                        });
                    }
                })
            },

            submitMobile() {
                if (this.saveBtnLoading) {
                    return false;
                }

                this.$refs['editForm'].validate((valid) => {
                    if (valid) {
                        this.saveBtnLoading = true;
                        phoneService.setPhone({
                            telephoneNumber: '',
                            mobileNumber: this.editForm.mobileInput,
                            verifyCode: this.editForm.msgCode
                        }).then(res => {
                            const obj = {
                                number: this.editForm.mobileInput,
                                numberType: 0
                            };
                            this.actualMobile = this.editForm.mobileInput;
                            this.phoneList.forEach((phone) =>{
                                if(phone.numberType == 0){
                                    phone.number = this.editForm.mobileInput;
                                }
                            })
                            shortTips('更新手机号成功！');
                            this.editStatus = 0;
                            
                            // 加华为白名单
                            phoneService.addMobileWhiteList(this.editForm.mobileInput);
                        }).finally(() => {
                            this.saveBtnLoading = false;
                        });
                    }
                })
            },
            setTelephone() {
                this.editStatus = 1;
                this.editForm.telephoneInput = '';
            },
            handleEditPhone(item) {
                if (item.numberType == 0) {
                    this.editStatus = 2;
                    this.editForm.mobileInput = item.number;
                    this.handleMobileChange();
                }else if (item.numberType == 1) {
                    this.editStatus = 3;
                    this.editForm.telephoneInput = item.number;
                }
            },
            handleDeleteTelephone() {
                this.deleteDialogVisible = true;
            },
            deleteTelephone() {
                // this.deleteDialogVisible = false;
                // console.log('xx');

                if (this.deleteBtnLoading) {
                    return false;
                }

                this.deleteBtnLoading = true;
                phoneService.deleteTelephone({
                    telephoneNumber: this.actualTelephone
                }).then(res => {
                    shortTips('删除固话成功！');
                    let list = this.phoneList.filter(item => {
                        return item.numberType != 1;
                    });
                    this.phoneList = list;
                    this.deleteDialogVisible = false;
                }).finally(() => {
                    this.deleteBtnLoading = false;
                });
            },
            handleMobileChange() {
                if (internationalMobile(this.editForm.mobileInput)) {
                    this.canGetCode = true;
                } else {
                    this.canGetCode = false;
                }
            },
            getMsgCode() {
                if (this.codeLoading) {
                    return false;
                }

                this.codeLoading = true;
                phoneService.getMsgCode({
                    mobileNumber: this.editForm.mobileInput
                }).then(res => {
                    this.codeGetted = true;
                    shortTips("验证码发送成功，5分钟内未收到请重新获取");
                    this.startCountDown();
                }).finally(() => {
                    this.codeLoading = false;
                });
            },
            startCountDown() {
                this.timer = setInterval(() => {
                    if(this.countDown > 1) {
                        this.countDown--;
                        console.log(this.countDown);
                    }else {
                        this.countDown = 60*5;
                        this.timer && clearInterval(this.timer);
                        this.timer = null;
                    }
                }, 1000);
            },
        }
    }
</script>

<style scoped lang="scss">

.phone-call {
    cursor: pointer;
    // position: relative;

    .animate-dot{
        font-size: 18px;
        width: 20px;
        overflow: hidden;
        vertical-align: middle;
        display: inline-block;
        animation: animateDot 1s infinite step-start;
    }

    &-dialog {
        .authorization-tip {
            color: #F98F33;
            line-height: 24px;
            margin: 0;
            text-align: center;
        }
    }

    &-box {
        text-align: center;
        margin-top: -20px;

        /deep/ .el-form-item {
            margin-bottom: 0;

            &.mobile-item {
                margin-bottom: 20px;
            }
            &.mobile-item, &.msg-code-item {
                .el-form-item__error {
                    left: 104px;
                }
            }

            .el-form-item__content {
                line-height: initial;
            }
        } 
    }

    &-tip {
        font-size: 14px;
        color: #666;
        line-height: 30px;
        margin-top: 10px;
        margin-bottom: 0;
        text-align: left;
    }


    &-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;
        line-height: 34px;
        text-align: left;

        .edit-btn, .delete-btn {
            color: $primary;
            cursor: pointer;
        }
        .delete-btn {
            margin-left: 10px;
        }

        /deep/ .el-radio {
            margin-right: 16px;
        }

        /deep/ .el-radio__input {
            .el-radio__inner {
                width: 14px;
                height: 14px;
                border-color: #ccc;
            }
        }

        /deep/ .el-radio__label {
            color: #333;
        }
        /deep/ .el-radio__input.is-checked + .el-radio__label {
            color: $primary;
        }

        /deep/ .el-radio__input.is-checked {
            .el-radio__inner {
                background-color: #fff;
                &::after {
                    width: 8px;
                    height: 8px;
                    background-color: $primary;
                }
            }
        }
    }

    &-list radio:last-child {
        margin-left: 20px;
    }

    &-from-list {
        padding-top: 18px;
        border-top: 1px dashed #ccc;

        >p {
            margin-top: 20px;
            font-size: 12px;
            color: #999;
            text-align: left;

            i {
                color: #F5A623;
            }
        }
    }

    &-input-box {
        margin: 10px 0;

        /deep/ .el-input__inner {
            height: 34px;
            line-height: 34px;
        }

        .mobile-input-row {
            display: flex;
            justify-content: center;
            align-items: center;

            &.mobile-row {
                margin-top: 10px;
            }

            > span {
                flex-shrink: 0;
                margin-right: 10px;
            }

            /deep/ .el-input {
                width: 284px;
            }

            .get-code-btn{
                background: #fff;
                color: #ccc;
                cursor: pointer;
                width: 130px;
                height: 32px;
                margin-top: 1px;
                box-sizing: border-box;
                cursor: not-allowed;
                border: none;
                text-align: right;
                padding-right: 10px;
            }
            .green{
                // background: $primary;
                color: $primary;
                cursor: pointer;
            }
        }
    }

    &-input {
        display: inline-block;
        width: 60%;
        float: left;
        margin-left: 40px;
        padding: .8rem .75rem;
    }

    &-bottom {
        margin-top: 45px;
    }

    &-link {
        display: block;
        width: 100px;
        margin: 0 auto;
        line-height: 40px;
        text-align: center;
        color: #666;
    }

    &-set-btn {
        display: block;
        float: left;
        width: 86px;
        height: 40px;
        margin-left: 10px;
        text-align: center;
        line-height: 40px;
        font-size: 16px;
        color: $primary;
        border: 1px solid $primary;
        background-color: #fff;
        clear: right;
    }

    &-set-btn:hover {
        color: #fff;
        border: none;
        background-color: $primary;
    }

    &-btn {
        display: block;
        margin: 15px auto 0;
        width: 258px;
        height: 50px;
        border: none;
        background: $primary;
        border-radius: 2px;
        font-size: 16px;
        color: #fff;
    }

    &-result {
        //line-height: 165px;
        line-height: 1.4;
        text-align: center;
        font-size: 18px;
    }
}

@keyframes animateDot {
    0% {
        width: 0;
        margin-right: 20px;
    }
    33% {
        width: 7px;
        margin-right: 13px;
    }
    66% {
        width: 14px;
        margin-right: 6px;
    }
    100% {
        width: 20px;
        margin-right: 0;
    }
}
.phone-call-test{
    display: inline-block;
    vertical-align: middle;
}

</style>
<style lang="scss">
.el-dialog__wrapper.phone-call-dialog {
    .el-dialog__header {
        border: 0 none;
    }

    .el-dialog__body {
        // padding-bottom: 50px;
    }

    .el-dialog__headerbtn .el-dialog__close {
        // font-size: 24px;
    }
}
</style>