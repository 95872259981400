<template>
    <el-dialog
        title="添加提醒事项"
        :visible.sync="dialogVisible"
        width="608px"
        v-loading="loading"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <div class="create-notice-wrap">
            <div class="create-row">
                <span class="row-label"><i>*</i>提醒内容</span>
                <div class="row-content">
                    <div class="content-textarea-wrap" :class="isError?'is-error':''">
                        <textarea class="content-textarea" v-model="noticeContent" name="" id="" cols="30" rows="10" :placeholder="isError?'请先添加提醒内容，才能确定哦~':'添加内容提醒自己吧~如记得和候选人要简历'" :maxlength="maxLength"></textarea>
                        <div class="textarea-count" :class="noticeContent.length>50?'is-error':''">({{noticeContent.length}}/{{maxLength}})</div>
                    </div>
                    <div class="quick-list">
                        <span class="quick-item" v-for="(item, index) in quickItems" :key="index" @click="handleQuickItem(item)">{{item}}</span>
                    </div>
                </div>
            </div>
            <div class="create-row">
                <span class="row-label"><i>*</i>提醒时间</span>
                <div class="row-content">
                    <div class="timepicker-wrap">
                        <el-date-picker
                            class="notice-datepicker"
                            v-model="noticeDate"
                            type="date"
                            placeholder="选择日期"
                            prefix-icon="icon-notice-time"
                            size="large"
                            :clearable="false"
                            :picker-options="expireTimeOption"
                            :editable="false">
                        </el-date-picker>
                        <el-time-select
                            v-model="noticeTime"
                            class="notice-timepicker"
                            :picker-options="timeOption"
                            :clearable="false"
                            placeholder="选择时间"
                            :editable="false">
                        </el-time-select>
                    </div>

                    <div class="notice-tip">提醒事项会出现在页面工具箱中</div>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="createNotice">确定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import {mapActions} from 'vuex';
import moment from 'moment'
export default {
    name:'createNoticeDailog',
    data() {
        return {
            dialogVisible: false,
            noticeDate:'',
            noticeTime: '',
            quickItems:['待联系','跟候选人要简历','确定面试时间','沟通面试辅导'],
            noticeContent:'',
            maxLength: 50,
            isError: false,
            resume: '',
            loading: false,
            expireTimeOption: {
                disabledDate(date) {
                    return date.getTime() <= Date.now() - 24*60*60*1000;
                }
            },
            timeOption: {
                start: '00:00',
                step: '00:15',
                end: '23:45'
            }
        }
    },
    watch: {
        dialogVisible(val,oldVal){
            if(!val){
                this.reset();
            }else{
                this.initDate();
            }
        },
        noticeContent(val,oldVal){
            if((oldVal == ''&& val!='')||(oldVal.length>50&&val.length<50)){
                this.isError = false;
            }
        },
        noticeDate(val,oldVal){
            this.setTimeOption(val);
        }
    },
    mounted() {
        this.initDate();
    },
    methods: {
        showDialog(resume, communicationInfo){
            this.resume = resume;
            this.dialogVisible = true;
            if(communicationInfo) {
                this.noticeContent = `沟通【${communicationInfo.customerName}】的职位【${communicationInfo.jobName}】。`;
            }
        },
        handleQuickItem(item){
            this.noticeContent += item;
        },
        createNotice(){
            if(this.noticeContent==''||this.noticeContent.length>50){
                this.isError = true;
                return;
            }
            let dataJson = {
                ResumeId: this.resume.resumeListItem.resumeId,
                RemindTime: `${moment(this.noticeDate).format('YYYY-MM-DD')} ${this.noticeTime}:00`,
                Content: this.noticeContent
            }
            this.loading = true;
            _request({
                method: 'POST',
                url: "/Resume/Reminder/Add",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: dataJson
            }).then(res => {
                this.loading = false;
                shortTips('添加成功');
                this.addCount();
                this.dialogVisible = false;
                if(this.resume.resumeListItem.resumeReminders && this.resume.resumeListItem.resumeReminders.length==0){
                    this.$emit('update-item', this.resume);
                }
            }).catch(err => {
                console.log(err)
                this.loading = false;
            })
        },
        reset(){
            this.noticeContent = '';
            this.isError = false;
            this.noticeTime = '';
            this.noticeDate = '';
        },
        initDate(){
            this.noticeDate = new Date();
            this.setTimeOption(this.noticeDate);
        },
        addCount(){
            if(new Date(this.noticeDate).toDateString() == new Date().toDateString()){
                let noticeCount = this.$store.state.notice.noticeCount;
                this.invokeSetNoticeCount(noticeCount+1);
            }
        },
        getDefaultTime(){
            let nowDate = new Date();
            let hour = nowDate.getHours()+1;
            let minute = Math.floor(nowDate.getMinutes()/15)*15;
            return `${hour>9?hour:'0'+hour}:${minute>9?minute:'0'+minute}`;
        },
        getStartTime(){
            let nowDate = new Date();
            let hour = nowDate.getHours();
            let minute = Math.ceil(nowDate.getMinutes()/15)*15;
            if(minute>=60){
                hour = hour + 1;
                minute = 0;
            }
            return `${hour>9?hour:'0'+hour}:${minute>9?minute:'0'+minute}`;
        },
        setTimeOption(date){
            let today = moment().format('YYYY-MM-DD');
            let time = this.getDefaultTime();
            let startTime = this.getStartTime();
            if(moment(date).format('YYYY-MM-DD') == today){
                this.timeOption = {
                    start: startTime,
                    step: '00:15',
                    end: '23:45'
                }
            }else{
                this.timeOption = {
                    start: '00:00',
                    step: '00:15',
                    end: '23:45'
                }
            }
            this.noticeTime = time;
        },
        ...mapActions('notice',[
            'invokeSetNoticeCount'
        ])
    },
}
</script>
<style lang="scss" scoped>
.create-notice-wrap{
    font-size: 14px;
    color: #999;
    padding: 0 25px;
    .create-row{
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .row-label{
            padding-top: 10px;
            color: #000;
            >i{
                color: #FF6564;
                margin-right: 4px;
            }
        }
        .row-content{
            width: 418px;
            .content-textarea-wrap{
                padding: 10px;
                border: 1px solid #ddd;
                .textarea-count{
                    text-align: right;
                    font-size: 12px;
                    &.is-error{
                        color: #FC7859;
                    }
                }
                &.is-error{
                    border: 1px solid #FC7859;
                    .content-textarea{
                       &::placeholder{
                        color: #FC7859;
                    }
                    }
                }
            }
            .content-textarea{
                width: 100%;
                height: 72px;
                resize: none;
                outline: none;
                border-radius: 2px;
                color: #666;
                border: none;
            }
        }
    }
    .quick-list{
        margin: 10px 0;
        font-size: 0;
        .quick-item{
            display: inline-block;
            font-size: 14px;
            height: 32px;
            line-height: 30px;
            border: 1px solid #ddd;
            border-radius: 2px;
            text-align: center;
            padding: 0 9px;
            margin-right: 10px;
            cursor: pointer;
            &:last-child{
                margin-right: 0;
            }
            &:hover{
                border: 1px solid #38BC9D;
                color: #38BC9D;
            }
        }
    }
    .notice-tip{
        font-size: 12px;
        margin-top: 10px;
    }
    .timepicker-wrap{
        display: flex;
        justify-content: space-between;
    }
    /deep/ .icon-notice-time{
        display: inline-block;
        width: 16px;
        background: url('~@src/assets/images/icon/calendar.svg') center no-repeat;
        margin: 0 10px;
    }
    /deep/ .el-input--prefix .el-input__inner{
        padding-left: 38px;
    }
    /deep/ .notice-datepicker.el-input,.notice-datepicker.el-input__inner{
        width: 260px !important;
    }
    /deep/ .notice-timepicker.el-input,.notice-timepicker.el-input__inner{
        width: 150px !important;
    }
}
</style>
