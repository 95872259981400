var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        title: "添加提醒事项",
        visible: _vm.dialogVisible,
        width: "608px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "create-notice-wrap" }, [
        _c("div", { staticClass: "create-row" }, [
          _c("span", { staticClass: "row-label" }, [
            _c("i", [_vm._v("*")]),
            _vm._v("提醒内容"),
          ]),
          _c("div", { staticClass: "row-content" }, [
            _c(
              "div",
              {
                staticClass: "content-textarea-wrap",
                class: _vm.isError ? "is-error" : "",
              },
              [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.noticeContent,
                      expression: "noticeContent",
                    },
                  ],
                  staticClass: "content-textarea",
                  attrs: {
                    name: "",
                    id: "",
                    cols: "30",
                    rows: "10",
                    placeholder: _vm.isError
                      ? "请先添加提醒内容，才能确定哦~"
                      : "添加内容提醒自己吧~如记得和候选人要简历",
                    maxlength: _vm.maxLength,
                  },
                  domProps: { value: _vm.noticeContent },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.noticeContent = $event.target.value
                    },
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass: "textarea-count",
                    class: _vm.noticeContent.length > 50 ? "is-error" : "",
                  },
                  [
                    _vm._v(
                      "(" +
                        _vm._s(_vm.noticeContent.length) +
                        "/" +
                        _vm._s(_vm.maxLength) +
                        ")"
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "quick-list" },
              _vm._l(_vm.quickItems, function (item, index) {
                return _c(
                  "span",
                  {
                    key: index,
                    staticClass: "quick-item",
                    on: {
                      click: function ($event) {
                        return _vm.handleQuickItem(item)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item))]
                )
              }),
              0
            ),
          ]),
        ]),
        _c("div", { staticClass: "create-row" }, [
          _c("span", { staticClass: "row-label" }, [
            _c("i", [_vm._v("*")]),
            _vm._v("提醒时间"),
          ]),
          _c("div", { staticClass: "row-content" }, [
            _c(
              "div",
              { staticClass: "timepicker-wrap" },
              [
                _c("el-date-picker", {
                  staticClass: "notice-datepicker",
                  attrs: {
                    type: "date",
                    placeholder: "选择日期",
                    "prefix-icon": "icon-notice-time",
                    size: "large",
                    clearable: false,
                    "picker-options": _vm.expireTimeOption,
                    editable: false,
                  },
                  model: {
                    value: _vm.noticeDate,
                    callback: function ($$v) {
                      _vm.noticeDate = $$v
                    },
                    expression: "noticeDate",
                  },
                }),
                _c("el-time-select", {
                  staticClass: "notice-timepicker",
                  attrs: {
                    "picker-options": _vm.timeOption,
                    clearable: false,
                    placeholder: "选择时间",
                    editable: false,
                  },
                  model: {
                    value: _vm.noticeTime,
                    callback: function ($$v) {
                      _vm.noticeTime = $$v
                    },
                    expression: "noticeTime",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "notice-tip" }, [
              _vm._v("提醒事项会出现在页面工具箱中"),
            ]),
          ]),
        ]),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.createNotice } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }